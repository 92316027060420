.searchTitleDisabled {
  color: #3e3f42;
  float: left;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  margin-left: 15px;
}

.documentContainer {
  border: 1px solid #eaedf3;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.04);
  width: 250px;
  margin: 15px;
  display: inline-block;
  float: left;
}

.heading {
  color: #3e3f42 !important;
  display: block;
  padding: 15px;
  width: 85%;
  /*height: 95px;*/
  height: 25px;
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.libraryIcon {
  width: 170;
  height: 170px;
  padding-bottom: 25px;
}

.documentList {
  clear: both;
}

.iconContainer {
  text-align: center;
}

.lockIcon{
    width: 48px;
    height: 48px;
    margin: 10% auto 1% auto;
    display: block;
}

.noLibraryMessage{
  font-size: 30px;
  font-weight: 500;
  line-height: 1.47;
  text-align: center;
  color: #3e3f42;
  margin: 0 0 0 0;
}

.noLibrarySubMessage {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  text-align: center;
  color: #6b6c6f;
}

