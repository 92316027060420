.pdfDocumentContainer {
  text-align: center;
}

.pdfDocument {
  display: inline-block;
  margin: 0 auto !important;
}

.pdfDocumentPage {
  border: 1px solid #eaedf3;
  box-shadow: 0 5px 13px 0 rgba(83,96,107,0.15);
  text-align: center;
  margin: 0 auto 100px auto !important;
}

.pdfDocumentPage .canvas {
  margin: 0 auto !important;
}

.documentPageContainer {
  float: left;
  margin-right: 10px;
}
