.libraryCreateContainer {
  background-color: #fbfbfb;
  margin-top: 30px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 5px;
}

.createContainer {
  padding: 0 20px;
}

.createActionButtons {
  padding: 20px;
}
