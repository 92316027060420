.block {
  border: 1px solid #eaedf3;
  background-color: #ffffff;
  margin-left: 23px !important;
  margin-top: 20px;
  margin-bottom: 20px;
  .content {
    max-width: 466px;
    margin: 24px;
    .heading {
      font-size: 18px;
      font-weight: 500;
      color: #3e3f42;
      .subHeading {
        font-size: 12px;
        margin-top: 8px;
        color: #9ea0a5;
      }
    }
    .date {
      margin-bottom: 10px;
      .react-datepicker-wrapper {
        display: block;
        margin-top: 5px;
        & ~ .error{
          margin-top: 5px; 
          color: #e67670;
          font-size: 12px;
        }
        input.error{
          border-color: #e67670;
        }
      }
    }
  }
  .reports {
    .report-name {
      cursor: pointer;
      color: #3e3f42;
      font-size: 14px;
      margin: 10px 30px;
      font-weight: 500;
    }
  }
}
.changeButton {
  color: #1B8C96;
  background-image: none;
  border-color: #1B8C96;
  font-weight: 500;
}

.changeButton:hover {
  color: #1B8C96;
  background-image: none;
  border-color: #1B8C96;
}

.headline {
  font-weight: 600;
  margin: 0px 0px 0px 5px;
  font-size: 18px;
  color: #3E3F42;
  padding-top: 32px;
  padding-left: 20px;
}