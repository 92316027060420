.checkbox-container {
    display:flex;
    align-items:center;
    position: relative;
    
    .checkbox {
        -webkit-appearance: none;
        height: 16px;
        width: 16px;
        background: linear-gradient(0deg, #F6F7F9 0%, #FFFFFF 100%);
        border-radius: 3px;
        border: 1px solid #D8DCE6;
        margin: 0;
        &:hover {
            cursor: pointer;
        }
        &:focus {
            outline: none;
        }
        &.checked {
            background: #1B8C96;
            border: 1px solid #1B8C96;
        }
    }
    .label {
        margin-left: 10px;
        font-size: 14px;
        font-weight: 500;
        color: #3E3F42;
    }
    .checkmark {
        position: absolute;
        pointer-events: none;
        &.hidden {
            display: none;
        }
    }
}