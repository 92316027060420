.approvalForm {
  padding: 0 !important;
  margin: 0 !important;
}
h4 {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin: 0 0 10px 0;
  padding: 0;
}

.approvalFormTitle {
  font-size: 12px;
  color: #6B6C6F;
  margin: 0;
}

.approvalFormDueDate {
  color: hsla(222.85714285714292, 3.74%, 63.33%, 1);
  display: block;
  font-size: 12px;
  margin: 0 0 10px 0;
}

.approvalOption {
  padding: 5px 0;
}

.approvalSelect {
  border: 1px solid #D6DCE8;
  height: 34px;
  border-radius: 4px;
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 14px;
  cursor: pointer;
}

.approvalSelectReadOnly {
  cursor: auto;
}

.approvalSelectFocused {
  border: 1px solid #1B8C96;
}

.approvalLabel {
  font-size: 14px;
  font-weight: 500;
  color: #393D50;
}

.approvalBoxContainer {
  position: absolute;
  width: calc(100% - 30px);
  box-shadow: 0px 0px 16px rgba(28, 20, 1, 0.16);
  border-radius: 8px;
  background: white;
  z-index: 1;
  margin-top: 8px;
}

.approvalBoxContent {
  padding: 19px 24px;
}

.passwordConfirmation {
  border-top: 1px solid #EAEDF3;
  padding: 24px;
  :global(.button-primary__original) {
    width: 100%;
    margin-top: 8px;
    &:disabled {
      color: white;
      border: 1px solid #166F77;
      background: linear-gradient(0deg, #1B8C96 2.22%, #1B8C96 56.57%, #1B8C96 98.44%);
      opacity: 0.5;
    }
  }
  :global(.uik-input__input) {
    &:focus {
      border-color: #1B8C96;
    }
  }
}

.confirmPasswordLabel {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  color: #3E3F42;
  margin-bottom: 8px;
}

.passwordInvalid {
  .passwordInvalidLabel {
    color: #E67670;
    font-size: 12px;
  }
  :global(.uik-input__input) {
    border-color: #E67670;
  }
}
