.setpassword-container {
    .uik-content-title__wrapper {
        color: #3E3F42;
    }
    .uik-input__input {
        &:focus {
          border-color: #1b8c96;
        }
    }

    .button-primary__original {
        width: 100%;
        &:disabled {
            color: white;
            border: 1px solid #166F77;
            background: linear-gradient(0deg, #1B8C96 2.22%, #1B8C96 56.57%, #1B8C96 98.44%);
            opacity: 0.5;
          }
      
    }
}