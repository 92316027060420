.documentViewer {
  min-width: 1280px;
  height:100vh;
  width:100vw; 
  overflow-x:scroll; 
}

.documentContainer {
  padding: 0 !important;
}

.header {
  border-top: 1px solid #eaedf3;
  border-bottom: 1px solid #eaedf3;
  color: #3e3f42;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
  padding: 20px 30px;
  background-color: #fff;
}

.headerLegacy {
  margin-bottom: 40px;
}

.sidePanelContainer {
  padding-left: 0 !important;
  padding-right: 0 !important;
  overflow-y: auto;
  height: 100vh;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  width: 25%;
}

.sidePanel {
  border-left: 1px solid #eaedf3;
  border-top: 1px solid #eaedf3;
  /*height: 100vh;*/
  /*min-height: 97vh;*/
  /*max-height: 100%;*/
  background-color: #fff;
}

.sidePanelSelectedText {
  background-color: #393d50;
  border-left: 1px solid #eaedf3;
  min-height: 100vh;
  height: 100%;
}

.sidePanelHeader {
  border-bottom: 1px solid #eaedf3;
  color: #3e3f42;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin: 0;
  padding: 20px;
  padding-top: 26px;
}

.sidePanelOptions {
  border-bottom: 1px solid #eaedf3;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  padding: 13px 30px;
  &:hover {
    .approvalsRemaining {
      transform: translateX(8px);
      transition-duration: 0.15s;
    }
  }
}

.documentActionsDropDown {
  float: right;
}

.documentActionsDropDownContainer {
  color: #9ea0a5;
  cursor: pointer;
  font-size: 10px;
}

.backLink {
  color: #b6b6b6 !important;
  margin-right: 10px;
}

.renameContentContainer {
}

.renameContentHeadline {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  padding: 5px 25px;
}

.renameContentFormContainer {
  padding: 24px;
}

.renameContentFormButtons {
  padding: 20px 30px;
}

.renameCancelButton {
  margin-right: 20px;
}

.approvalsLink {
  color: #3e3f42;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.approvalsRemaining {
  color: hsla(184.8780487804878, 69.49%, 34.71%, 1);
  display: block;
  float: right;
}

.approvalsRemainingIcon {
  i {
    float: right;
    color: #b6b6b6;
    font-size: 16px;
    margin-top: 2px;
  }
}

.approvals {
  display: block;
  float: left;
}

.uploadNewVersionLink {
  color: #3e3f42 !important;
}

.conversationIconContainer {
  background-color: #ff9c79;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
  height: 28px;
  width: 28px;
}

.conversationIcon {
  font-size: calc(28px / 2); /* 50% of parent */
  line-height: 1;
  position: relative;
  top: calc(28px / 7); /* 25% of parent */
}

.closeComment {
  color: #9ea0a5;
  font-size: 20px;
  float: right;
  height: 16px;
  width: 16px;
}

.notCommentPanel {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.metadataField {
  margin-top: 10px;
}

.textMetadataField {
  margin: 10px 0 10px 0;
}

.metadataFieldLabel {
  color: #9b9b9b;
  display: block;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-transform: uppercase;
}

.metadataFieldValue {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #393d50;
}

.metadataSingleLineInputFields {
  position: relative;
  float: left;
  width: 45%;
}

.metadataMultilineInputFields {
  float: left;
  margin-left: 30px;
  width: 50%;
}

.metadataFormContainer {
  padding: 15px;
}

.editContentDetailsHeading {
}

.editDocumentFieldValuesForm {
  padding-bottom: 10px;
  clear: both;
}

.spacing {
  margin-bottom: 20px;
}

.tag {
  display: inline-block;
  background-color: #eaedf3;
  border-radius: 4px;
  padding: 4px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.tagsContainer {
  margin-top: 10px;
  max-width: 250px;
}

.documentMessageContainer {
  width: 600px;
  margin: 212px auto 0;
  text-align: center;
}

.documentMessageContainerIcon {
  margin-bottom: 33px;
}

.documentMessageContainerMessage {
  font-size: 30px;
  line-height: 44px;
  margin-bottom: 52px;
}

.documentMessageContainerButton {
  background: #1b8c96 !important;
  color: #fff;
  a {
    color: #fff;

    &:hover {
      color: #fff;
    }
  }
}

.webViewer {
  height: 100%;
  width: 100%;
}

.invalidDocument {
  margin: 271px auto;
  max-width: 716px;
  img {
    display: block;
    margin: 0 auto;
    width: 48px;
  }
  .heading {
    margin-top: 30px;
    margin-bottom: 60px;
    text-align: center;
    font-size: 30px;
    font-weight: 500;
  }
  a {
    button {
      margin: 0 auto;
      display: block;
      width: 110px;
    }
  }
}

.processingErrorButtons {
  display: flex;
  justify-content: center;
}

.processingError {
  margin: 134px auto;
  max-width: 716px;
  img {
    display: block;
    margin: 0 auto;
    width: 48px;
  }
  .heading {
    margin-top: 30px;
    margin-bottom: 60px;
    text-align: center;
    font-size: 30px;
    font-weight: 500;
  }
  button {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.processingErrorUploadButton {
  width: 188px;
}

.processingErrorPreviousVersionButton {
  width: 225px;
}

.viewerContentDeatils {
  color: #3e3f42;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  display: block;
  float: left;
}

.libraryNotUpdatable {
  font-size: 11px;
  color: #393d50;
  font-weight: 500;
  line-height: normal;
  display: flex;
  margin-bottom: 20px;
  
  .libraryNotUpdatableText {
    margin-left: 10px;
  }
}

.downloadModalOption {
  clear: both;
  display: block;
  line-height: 30px;
  margin-top: 20px;
}

.downloadModalOption:first-of-type {
  margin-top: 0px;
}

.downloadModalOption .downloadButton {
  float: right;
}
