.versionMessageContainer {
  padding: 3px 8px;
  background-color: #e67670;
  height: 18px;
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
  line-height: 18px;
  color: #fff;
  margin-left: 24px;
}


.closeVersion {
  margin-left: 24px;
  font-size: 16px;
  display: inline-block;
  cursor: pointer;
}