.documentsHeader {
  display: flex;
}

.noDocuments {
  font-style: italic;
  padding: 50px;
}

.searchContainer {
  float: right;
  display: flex;
}

.searchFormContainer {
  margin: 15px 15px 15px 0;
}

.searchClose {
  border: none;
  border-left: 1px solid #eaedf3;
  color: #9a9a9a;
  cursor: pointer;
  font-size: 28px;
  float: right;
  margin-left: 20px;
  padding: 0px 20px;
}

.searchInputContainer {
  border: none;
  border-bottom: 1px solid #1b8c96;
  float: right;
  padding: 0px !important;
  width: 325px;
}

.searchInput {
  background-color: transparent;
  border: none;
}

.searchIcon {
  color: #1b8c96;
  float: right;
  position: relative;
  top: 12px;
}

.searchIconDisabled {
  color: #9ea0a5;
  cursor: pointer;
  float: right;
  position: relative;
  top: 12px;
}

.documentList {
  position: relative;
  clear: both;
}

.documentListOver {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fbfbfd;
  opacity: 0.8;
  z-index: 900;
}

.searchTitle {
  color: #1b8c96;
  float: left;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  margin-left: 15px;
  margin-top: 30px;
}

.searchTitleDisabled {
  color: #3e3f42;
  float: left;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 68%;
  white-space: nowrap;
}

.truncatedTitle {
  color: #3e3f42;
  float: left;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 45%;
  white-space: nowrap;
}

.leftIcon {
  float: left;
  margin: 1.3rem 0.5rem;
}

.filterContainers {
  clear: both;
  display: flex;
  margin-left: 15px;
  flex-wrap: wrap;
}

.filterContainer {
  margin-right: 10px;
  margin-top: 7px;
}

.filterLabel {
  margin-bottom: 5px;
}

.filterContainerInner {
  width: 300px;
  background: #fff;

  color: #9ea0a5;
  font-size: 12px;
  text-transform: none;
  line-height: 16px;

  &.large {
    width: 400px;
  }
}

.filterActive {
  color: #1b8c96;
  font-weight: 500;
}

.filterActive + div > img{
  margin-top: 2px;
}

.selectedDateType {
  background: #1b8c96 !important;
  color: #fff;
}

.dateFilterContainer {
  height: 100%;
  width: 400px;
  background: #fff;

  &:hover {
    background: #fff;
  }
}

.dateLabel{
  margin-bottom: 0.5rem;
}

.dateFilterButtonContainer {
  width: 100%;

  margin: 0 0 1.5rem;
}

.dateFilterPickerContainer {
  width: 100%;
  height: 100%;
  clear: both;
  display: block;
}

.dropDownButton {
  border: solid 1px #dddddd !important;
  color: #9ea0a5;
  padding: 10px 60px;
}

.dropDownButtonActive {
  border: solid 1px #1b8c96 !important;
  color: #fff;
  background: #1b8c96 !important;
}

.filterContainerFooter {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}

.dropDownSelectOption {
  display: flex;
  justify-content: space-between;
  min-width: 200px;
  height: 22px;
  padding: 11px 1rem;
  font-size: 14px;
  line-height: 22px;
  color: #393d50;
  border: 1px solid #dddddd;
  border-bottom: none;

  cursor: pointer;

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: none;
  }

  &:last-child {
    border-top: 1px solid #dddddd;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    border-bottom: 1px solid #dddddd;
  }
}

.documentsActionsDropDownContainer {
  color: #9ea0a5;
  cursor: pointer;
  font-size: 10px;
}

.sortContainer {
  position: relative;
  float: right;
  top: 10px;

  margin-left: 30px;
  img {
    width: 20px;
    height: 20px;
  }
}

.datePickerContainer {
  &:nth-of-type(2) {
    margin: 0 30px;
  }
}
