.documentReferencePanel {
  position: absolute;
  background-color: #393d50;
  color: #fff;
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 25%;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar{
    display: none;
  }
}

.sidePanelOptions {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}

.emptySidePanelOptions {
  background-color: #2C3041;
  border-radius: 4px;
  padding: 10px 20px;
  margin: 30px;
  vertical-align: middle;
  height: calc(100vh - 155px) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emptyStateMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.emptyStateMessageLineOne {
  margin: 20px 0px 20px 0px;
}

.sidePanelHeader {
  border-bottom: 1px solid rgba(234, 237, 243, 0.2);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin: 0;
  padding: 20px;
}

.documentReferenceIconContainer {
  background-color: #ff9c79;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
  height: 28px;
  width: 28px;
}

.documentReferenceIcon {
  font-size: calc(28px / 2); /* 50% of parent */
  line-height: 1;
  position: relative;
  top: calc(28px / 7); /* 25% of parent */
}

.closeComment {
  color: #9ea0a5;
  cursor: pointer;
  font-size: 26px;
  float: right;
  height: 16px;
  width: 16px;
}

.button {
  margin: 10px auto 10px auto;
  position: relative;
  width: 90%;
}

.referenceLink {
  color: #eaedf3 !important;
  font-weight: normal;
}

.referenceContentUploading {
  background-color: #393d50;
  border-bottom: 1px solid rgba(234, 237, 243, 0.2);
  padding: 10px 20px;
  vertical-align: middle;
  word-break: break-word;
}

.referenceCaption {
}

.referenceDocumentTitle {
  float: right;
  word-break: break-word;
}

.attachmentDocumentTitle {
  float: left;
}

.referenceActionsDropDownContainer {
  float: right;
  margin-right: 15px;
  cursor: pointer;
}

.moreIcon {
  position: relative;
  top: -3px;
}

.sidePanelHeadingTitle {
  font-size: 24px;
  font-weight: 500;
}