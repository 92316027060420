.uik-checkbox__wrapper.uik-checkbox__radio {
    .uik-checkbox__label {
        color: #3E3F42;
        font-weight: 500;
    }
    .uik-checkbox__checkbox:checked {
        & + .uik-checkbox__label {
            color: #1B8C96;
            font-weight: bold;
            &:before {
                background-image: linear-gradient(0deg, #1B8C96 0%, #1B8C96 100%);
            }
            &:after {
                height: 6px;
                width: 6px;
                top: 6px;
                left: 6px;
            }
        }
    }
}