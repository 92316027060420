.setupForm .uik-form-input-group__vertical {
    margin-top: 30px;
}

.setupForm .uik-input__errorMessage {
    margin-bottom: 0px;
}

.setupForm .uik-content-title__wrapper {
    text-transform: none;
    font-weight: 600;
    color: #3E3F42;
}

.setupForm .button-primary__original {
    width: 100%;
}

.setupForm .button-primary__original.disabled {
    color: #e9f4f5;
    background-color: #8dc6cb;
}