.selectedTextPanel {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.sidePanelOptions {
  border-bottom: 1px solid #eaedf3;
	font-size: 14px;
	font-weight: 600;
	line-height: 22px;
  padding: 13px 30px;
}

.sidePanelHeader {
  border-bottom: 1px solid #eaedf3;
  border-top: 1px solid #eaedf3;
	color: #3e3f42;
	font-size: 14px;
	font-weight: 600;
	line-height: 22px;
  margin: 0;
  padding: 20px;
}

.selectedTextIconContainer {
  background-color: #7a6a8d;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
  height: 28px;
  width: 28px;
}

.selectedTextIcon {
  font-size: calc(28px / 2); /* 50% of parent */
  line-height: 1;
  position: relative;
  top: calc(28px / 7); /* 25% of parent */
}

.closeComment {
  color: #9ea0a5;
  cursor: pointer;
  font-size: 22px;
  float: right;
  height: 16px;
	width: 16px;
}
