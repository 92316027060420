.tagsScreen {
  min-height: 100vh;
}

.tagsScreen__container {
  /*background-color: #fbfbfb;*/
  min-height: 100vh;
}

.tagsScreen__tagListContainer {
  border-left: 1px solid #eaedf3;
  border-right: 1px solid #eaedf3;
  padding: 0px !important;
}

.tagsScreen__tagSearch {
  border-bottom: 1px solid #eaedf3;
  padding: 13px 22px;
}

.tagsScreen__tagSearchInput {
  border: none;
}

.tagSearch__inputContainer {
  display: inline-block;
  width: 70%;
}

.tagInvite__buttonContainer {
  float: right;
}
