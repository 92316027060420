.linkExpiredPage {
  height: 100vh;
  .hsChatContainer {
    float: right;
    margin-top: 16px;
    margin-right: 30px;
      .hs-chat {
        background-color: transparent;
      }
      .hs-chat__closed {
        background-color: transparent;
      }
  }
  .body {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;

      .container {
          background: #FFFFFF;
          border: 2px solid #EAEDF3;
          box-sizing: border-box;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
          border-radius: 8px;
          min-height: auto;
          width: 384px;
          padding: 32px;
          display: flex;
          flex-direction: column;

          .logoContainer {
            display: flex;
            justify-content: center;
            height: 30px;
          }
  
          .emailFormContainer {
  
              margin-top: 32px;
  
              .title {
                  font-weight: 600;
                  font-size: 16px;
              }
      
              .description {
                  font-weight: 500;
                  font-size: 12px;
                  color: #9EA0A5;
                  margin-top: 8px;
                  line-height: 22px;
              }
  
              .uik-form-input-group__vertical {
                  margin-top: 38px;
      
                  .uik-content-title__wrapper {
                      text-transform: none;
                      font-weight: 600;
                      color: #3E3F42;
                  }
              }
          }
      }

  }
}

[class^="inputGroup"] {
  margin-top: 20px;
}

[class^="hs-chat"] {
  background-color: transparent;
}