.login-page {
    height: 100vh;
    .hs-chat-container {
        .hs-chat {
            background-color: white;
        }
        .hs-chat__closed {
            background-color: transparent;
        }
    }
    .body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

        .messageContainer {
            width: 332px;
            background-color: #393D4f;
            margin-bottom: 30px;
            border-radius: 8px;
            color: white;
            padding: 20px 25px 20px 25px;

            .headingContainer {
                display: flex;
                align-items: center;
                
                .warningHeading {
                    font-weight: 600;
                    font-size: 16px;
                    margin-left: 15px;
                }
            }
                            
            .warningMessage {
                font-size: 12px;
                margin-top: 0px;
            }
        }
        .container {
            background: #FFFFFF;
            border: 2px solid #EAEDF3;
            box-sizing: border-box;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
            border-radius: 8px;
            min-height: auto;
            width: 384px;
            padding: 32px;
            display: flex;
            flex-direction: column;

            .logoContainer {
                display: flex;
                justify-content: center;
            }
    
            .email-password-content {
                .uik-form-input-group__vertical {
                    margin-top: 38px;
        
                    .uik-content-title__wrapper {
                        text-transform: none;
                        font-weight: 600;
                        color: #3E3F42;
                    }
                    .uik-input__input {
                        color: #393D50 !important;
                        font-weight: 500;
                    }
                    .uik-input__input:-ms-input-placeholder {
                        color: #bfbfbf !important;
                    }
                    input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
                        -webkit-text-fill-color: #393D50 !important;
                    }
                }
    
                .button-primary__original {
                    width: 100%;
                    &:disabled {
                        color: white;
                        border: 1px solid #166F77;
                        background: linear-gradient(0deg, #1B8C96 2.22%, #1B8C96 56.57%, #1B8C96 98.44%);
                        opacity: 0.5;
                      }
                  
                }
    
                .uik-input__errorMessage {
                    margin-bottom: 0;
                }
    
                .uik-input__input {
                    &:focus {
                      border-color: #1b8c96;
                    }
                }
            }
    
            .forgot-password {
                margin-top: 10px;
                button {
                    border: none;
                    background-color: transparent;
                    color: #1B8C96;
                    margin: 0 auto;
                    display: block;
                    font-weight: 500;
                    cursor: pointer;
                }
            }
    
            .setup-2fa,.verify-2fa,.reset-password {
                margin-top: 32px;
    
                .title {
                    font-weight: 600;
                    font-size: 16px;
                }
    
                .description {
                    font-weight: 500;
                    font-size: 12px;
                    color: #9EA0A5;
                    margin-top: 8px;
                    line-height: 22px;
                    .update-phone-number {
                        color: #1B8C96;
                        cursor: pointer;
                    }
                }
    
                .verify2fa-container {
                    margin-top: 34px;
                }
            }
    
            .reset-password {
                .description {
                    margin-bottom: 32px;
                }
    
                .uik-content-title__wrapper {
                    text-transform: none;
                    color: #3E3F42;
                }
    
                .uik-input__errorMessage {
                    margin-bottom: 0;
                }
    
                .button-primary__original {
                    width: 100%;
                    margin-top: 16px;
                    &:disabled {
                        color: white;
                        border: 1px solid #166F77;
                        background: linear-gradient(0deg, #1B8C96 2.22%, #1B8C96 56.57%, #1B8C96 98.44%);
                        opacity: 0.5;
                      }
                  
                }
                .uik-input__input {
                    &:focus {
                      border-color: #1b8c96;
                    }
                }
    
                &.error {
                    .uik-input__input {
                        border-color: #E67670;
                    }
                }
                .error-field {
                    color: #E67670;
                    margin-top: 8px;
                    font-weight: 500;
                    font-size: 12px;
                }
            }
        }
    }
}