.usersScreenHeaderContainer {
  height: 70px;
}

.usersScreenHeader {
  border: none;
  border-bottom: 1px solid #eaeaea;

  height: 70px;
  margin-bottom: 50px;
  text-align: middle;
  width: 100%;
  background-color: #fff;
  :global(.hs-chat-container) {
    margin-top: 20px;
    margin-right: 6px;
    :global(.hs-chat) {
      background-color: #eaedf3;
    }
    :global(.hs-chat__closed) {
      background-color: white;
    }
  }
}

.usersScreenHeaderLogo {
  display: inline-block;
  float: left;
}

.usersScreenHeaderMenu {
  display: inline-block;
  margin: 24px 60px;
}

.usersScreenHeaderMenu a {
  text-transform: uppercase;
}

.usersScreenHeaderMenu a {
  color: #9ea0a5;
  display: inline-block;
  font-family: Montserrat;
  letter-spacing: 0.8px;
  font-size: 12px;
  font-weight: 700;
  line-height: 22px;
  margin-right: 30px;
  text-decoration: none;
}

.selectedMenuItem {
	color: #1b8c96 !important;
}

.userNamePlateAvatar {
  background-color: #c0c8cf;
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  float: left;
  margin-right: 14px;
  padding: 5px;
  text-align: center;
  height: 26px;
  width: 26px;
}

.userNamePlateInitials {
  font-size: calc(24px / 2); /* 50% of parent */
  line-height: 26px;
  position: relative;
}

.headerNamePlate {
  float: right;
  margin: 20px;
}

.namePlate {
  cursor: pointer;
}

.namePlateName {
  color: #3e3f42;
  font-size: 14px;
  display: inline-block;
  position: relative;
  top: 6px;
}

.namePlateChevron {
  color: #c0c8cf;
  display: inline-block;
  margin-left: 10px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.divider {
  float: right;
  border-right: 1px solid #d6dce8;
  height: 21px;
  margin-top: 27px;
  margin-right: 5px;
}

.dropdownIcon {
  margin-right: 10px;
}

.firstDropdownItem {
  border-bottom: none;
}

.firstDropdownItem:hover {
  border-bottom: none;
}

.upgradeNotifierContainer {
  position: relative;
  .toggleDisabledContainer {
    display: none;
  }
  .toggleEnabledContainer {
    display: block;
    font-size: 12px;

    .disabledText {
      width: 367px;
      height: 150px;
      padding: 31px;
      box-shadow: 0 4px 20px 0 rgba(57, 61, 80, 0.5);
      background-color: #393d50;
      border-radius: 10px;

      position: absolute;
      top: 0;
      left: -455px;
      z-index: 100;

      .notificationTitle {
        margin: 0 44px 12px 0;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fff;
        .closeUpgradeNotifier {
          cursor: pointer !important;
          position: absolute !important;
          right: 31px !important;
        }
      }

      .gotIt {
        background: #1b8c96 !important;
        border: 1px solid #1b8c96;
        color: #fff;
        float: right;
        margin-top: 15px;
      }

      .notificationText {
        margin: 12px 31px 22px 0;
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.85;
        letter-spacing: normal;
        color: #eaedf3;
      }
    }

    .rightArrow {
      position: absolute;
      left: -29px;
      transform: rotate(-90deg);
      top: 15px;
      border-top: 5px solid #393d50;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
  }
}
