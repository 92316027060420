.password-forgot-page {
    height: 100vh;
    .hs-chat-container {
        .hs-chat {
            background-color: white;
        }
        .hs-chat__closed {
            background-color: transparent;
        }
    }

    .body {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

        .container {
            background: #FFFFFF;
            border: 2px solid #EAEDF3;
            box-sizing: border-box;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
            border-radius: 8px;
            min-height: auto;
            width: 384px;
            padding: 32px;
            display: flex;
            flex-direction: column;
    
            .email-content {
                .uik-form-input-group__vertical {
                    margin-top: 38px;
        
                    .uik-content-title__wrapper {
                        text-transform: none;
                        font-weight: 600;
                        color: #3E3F42;
                    }
                }
    
                .button-primary__original {
                    width: 100%;
                    &:disabled {
                        color: white;
                        border: 1px solid #166F77;
                        background: linear-gradient(0deg, #1B8C96 2.22%, #1B8C96 56.57%, #1B8C96 98.44%);
                        opacity: 0.5;
                      }
                  
                }
    
                .uik-input__errorMessage {
                    margin-bottom: 0;
                }
    
                .uik-input__input {
                    &:focus {
                      border-color: #1b8c96;
                    }

                    color: #393D50 !important;
                    font-weight: 500;
                }
    
                margin-top: 32px;
    
                .title {
                    font-weight: 600;
                    font-size: 16px;
                }
    
                .description {
                    font-weight: 500;
                    font-size: 12px;
                    color: #9EA0A5;
                    margin-top: 8px;
                    line-height: 22px;
                    .update-phone-number {
                        color: #1B8C96;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}