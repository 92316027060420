.documentContainer {
  border: 1px solid #eaedf3;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.04);
  width: 250px;
  margin: 15px;
  display: inline-block;
  float: left;
  overflow: hidden;
}

.documentContainerDark {
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 #2C3041;
  width: 250px;
  height: 294px;
  margin: 15px;
  display: inline-block;
  float: left;
  overflow: hidden;
}

.documentContainer:hover {
  border-color: #d4d9e2;
  box-shadow: 6px 6px 20px 4px #ececec;
  opacity: 1;
}

.documentContainerDark:hover {
  box-shadow: 6px 6px 20px 4px #2C3041;
  opacity: 1;
}

.documentContainer:hover .thumbnail {
  transform: scale(1.05);
  background-color: #fbfbfb;
}
.documentContainer:hover .nothumbnail {
  transform: scale(1.08);
  background-color: #fbfbfb
}

.heading {
  color: #3e3f42 !important;
  padding: 15px 15px 0px 15px;
  width: 220px;
  height: 45px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}

.headingText {
  width: 95%;
  display: block;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.closeFailedReference {
  color: rgba(57, 61, 80, 1);
  cursor: pointer;
  font-size: 32px;
  float: right;
  height: 16px;
  width: 16px;
  position: absolute;
  top: 5%;
  right: 5%;
}

.loaderHeading {
  color: #3e3f42 !important;
  padding: 15px 15px 0px 15px;
  width: 220px;
  height: 45px;
  overflow: hidden;
  background-color: #d3d4d9;
  display: flex;
  flex-direction: row;
}

.createdTime {
  position: static;
  bottom: 0px;
  padding-top: 5px;
  float: none;
  clear: none;
  color: #9ea0a5;
  font-size: 11px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
}

.annotationCount {
  margin-left: 7px;
}

.progressBar {
  display: flex;
  justify-content: center;
  background-color: #d3d4d9;
}

.progressBarError {
  display: flex;
  background-color: white;
}

.docInfoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 15px 0px 15px;
}

.thumbnail {
  background-color: #fbfbfb;
  display: block;
  width: 250px;
  height: 210px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-size: cover !important;
  transform: scale(1);
  transition: all 0.3s;
  animation: opac 0.8s;
}@keyframes opac{from{opacity:0} to{opacity:1}}

.errorMessage {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #E67670;
}

.warning {
  height: 15px;
  margin-right: 5px;
}

.errorBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0px 10px 15px;
}