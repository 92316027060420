.tooltip-container {
    position: relative;
    .tooltip{
        &:focus {
            outline: none;
        }
        &:hover {
            cursor: pointer;
        }
    }
    .tooltip-dimple {
        position: absolute;
        top: -5px;
        left: 22px;
        &.hidden {
            display:none;
        }
    }
    .content {
        position: absolute;
        top: 0;
        left: 30px;
        background-color: black;
        transform: translateY(-45%);
        border-radius: 8px;
        z-index: 1;
        padding: 10px;
        margin: 24px 24px 24px 0px !important ;
        &.hidden {
            display:none;
        }

        .list {
            list-style-type: none;
            font-size: 12px;
            .item {
                &-allowed {
                    font-weight: 600;
                    color: white;
                }
                &-disallowed {
                    color: #CCCCCC;
                }
                display:flex;
                align-items: center;
                margin: 8px;
                .text {
                    margin-left: 4px;
                    white-space: nowrap;
                }
            }
        }
    }
}