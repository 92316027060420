.versionsPanel {
  background-color: #393d50;
  color: #fff;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
  position: absolute;
  top: 0;
  right: 0;
  width: 25%;
  height: 100vh;
}

.version {
  padding: 0 20px;
}


.versionsList {
  border-bottom: 1px solid rgba(234, 237, 243, 0.2);
	font-size: 14px;
	font-weight: 600;
	line-height: 22px;
  overflow-y: auto;
  min-height: 0px;
}

.versionsListHeader {
  border-bottom: 1px solid rgba(234, 237, 243, 0.2);
  color: #fff;
	font-size: 14px;
	font-weight: 600;
	line-height: 22px;
  margin: 0;
  padding: 20px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  opacity: 1;
  background: #393d50;
  z-index: 99;
}

.closeVersions {
  color: #9ea0a5;
  cursor: pointer;
  font-size: 26px;
  float: right;
  height: 16px;
	width: 16px;
}

.sidePanelHeadingTitle {
  font-size: 24px;
  font-weight: 500;
}
