.approverForm {
  background: #fff;
  border: 1px solid #eaedf3;
  border-radius: 4px;
  margin: 10px auto;
  padding: 0 0 10px 0 !important;
  width: 730px;
  &.added {
    margin-top: 30px;
  }
}

.addApproverContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}

.approverFormDetails {
  padding: 20px 0;
}

.addApproverInput {
  width: 100%;
}

.addApproverButton {
  float: right;
  align-self: flex-end;
  button {
    &:disabled {
      background: #eaedf3 !important;
      border-color: #eaedf3;
      color: #9ea0a5;
      box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1),
        inset 0 2px 0 0 rgba(255, 255, 255, 0.06) !important;

      border: none !important;
    }
  }
}

.approverFormInputs {
  padding: 0 30px !important;
  margin-top: 20px;
}

.approverListItems {
  margin: 30px 0 0 !important;
  &.added {
    margin-top: 0 !important;
  }
}

.approverListItemsHeader {
  margin-left: 15px;
}

.approverAutosuggestItem {
  border: 1px #efefef solid;
  border-top: none;
  cursor: pointer;
}

.actionButtons {
  margin: 30px auto;
  width: 730px;
}

.approveAction {
  float: right;
}

.releaseContentForm {
  background-color: #393d50;
  color: #fff;
  display: block;
  margin: 0;
  padding: 30px;
  width: 670px;
  margin: 10px auto;
}

.releaseContentFormHeadline {
  font-size: 30px;
  font-weight: 500;
  line-height: 44px;
  margin: 0;
  padding: 0;
}

.releaseContentFormCaption {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 18px;
}

.releaseContentFormAction {
  color: #fff;
}

.revokeContentForm {
  background-color: #393d50;
  color: #fff;
  display: block;
  margin: 0;
  padding: 30px;
  width: 670px;
  margin: 10px auto;
}

.revokeContentFormHeadline {
  font-size: 30px;
  font-weight: 500;
  line-height: 44px;
  margin: 0;
  padding: 0;
}

.revokeContentFormCaption {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 18px;
}

.revokeContentFormAction {
  color: #fff;
  margin-right: 10px;
}

.uploadNewVersionLink {
  color: #fff !important;
}

.suggestionsRemainingContentHeadline {
  height: 30px;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  padding: 5px 25px;
}

.suggestionsRemainingContentFormContainer {
  padding: 28px 30px;
}
.suggestionsRemainingContentText {
  font-size: 14px;
  font-weight: 500;
}

.suggestionsRemainingContentFormButtons {
  padding: 20px 30px;
}

.suggestionsRemainingLeaveButton {
  margin-right: 20px;
  width: 96px;
  border-radius: 4px;
  border: solid 1px #1b8c96 !important;
  display: inline-block;
  color: #1b8c96;
}

.suggestionsRemainingStayButton {
  width: 96px;
  border-radius: 4px;
}

.approverFormDoneButton {
  border: solid 1px #1b8c96 !important;
  color: #1b8c96;
  padding: 10px 60px;
}

.errorIcon {
 padding: 5px 25px;
 float: left;
 width: 40px;
}

.dueDateLabel {
  margin-bottom: 0.5rem;
}

.dueDateInput {
  width: 100%;
}

.libraryNotUpdatable {
  font-size: 11px;
  color: #393d50;
  font-weight: 500;
  line-height: normal;
  display: flex;
  margin-bottom: 20px;
  
  .libraryNotUpdatableText {
    margin-left: 10px;
  }
}