.draftUploadForm {
  background: #fff;
  border: 1px solid #eaedf3;
  border-radius: 4px;
  margin: 30px auto;
  padding: 30px !important;
  width: 730px;
}

.uploadError {
  width: 470px;
  height: 22px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: #e67670;
  float: left;
  width: 86%;
}

.draftUploadFormActions {
  background: none;
  margin: 30px auto;
  text-align: right;
  width: 730px;
}

.mainContentUploader {
  border: 1px dashed #efefef;
  border-radius: 4px;
  padding: 10px 20px;
  vertical-align: middle;
}

.mainContentUploading {
  background-color: #fbfbfd;
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 10px 20px;
  vertical-align: middle;
}

.innerContentUploading {
  line-height: 30px;
  float: left;
  width: 100%;
}

.uploaderIcon {
  color: #9ea0a5;
  display: inline-block;
  line-height: 30px;
  margin: 10px 10px 0;
  float: left;
}

.uploaderIconCheck {
  color: #15cd93;
  display: inline-block;
  float: right;
  line-height: 22px;
  margin: 10px 0 0 0;
  float: left;
}

.uploaderPlaceholder {
  color: #9ea0a5;
  font-size: 14px;
  line-height: 45px;
}

.uploaderButton {
  float: right;
}

.uploadProgress {
  height: 4px;
  width: 100%;
  border-radius: 2px;
}

.uploadingFilename {
  color: #3e3f42;
  font-size: 14px;
  line-height: 45px;
  float: left;
  padding-top: 2px;
  width: 86%;
}

.referenceContentUploader {
  border: 1px dashed #efefef;
  border-radius: 4px;
  padding: 10px 20px;
  vertical-align: middle;
}

.referenceContentUploading {
  background-color: #fbfbfd;
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 10px 20px;
  vertical-align: middle;
}

.checkMark {
  width: 32px;
  height: 32px;
  object-fit: contain;
}

.metadataSingleLineInputFields {
  float: left;
  width: 45%;
}

.librarySelect {
  margin-bottom: 10px;
}

.audienceSelect {
  margin-bottom: 10px;
}

.metadataMultilineInputFields {
  float: left;
  margin-left: 30px;
  width: 50%;
}

.processingContainer {
  float: right;
  line-height: 32px;
}

.selectedUploadType {
  background: #1b8c96 !important;
  color: #fff;
}

.formatNote {
  color: #9EA0A5;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-top: 0px; 
  margin-bottom: 0px; 
}

.formatNoteContainer {
  margin-top: 30px !important;
}

.metadataField {
  margin-top: 10px;
}

.textMetadataField {
  margin: 10px 0 10px 0;
}

.metadataFieldLabel {
  color: #9b9b9b;
  display: block;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-transform: uppercase;
}

.metadataFieldValue {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #393d50;
}
