.mentionSuggestionsEntryAvatar {
  font-size: 12px;
  font-weight: 600;
  width: 28px;
  height: 28px;
  line-height: 28px;
  background-color: #393D50;
  text-align: center;
  border-radius: 50%;
}

.mentionSuggestionsEntryFocused {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 7px 9px;
  background-color: rgb(45, 47, 56);
}
.mentionSuggestionsEntry {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 7px 9px;
}

.mentionSuggestions {
  position: absolute;
  width: calc(100% - 30px);
  background: #54586B;
  cursor: pointer;
  left: 15px !important;
  z-index: 2;
}

.mention {
  background-color: rgb(45, 47, 56);
}

.mentionSuggestionsEntryText {
  font-size: 12px;
  font-weight: 500;
}

.mentionSuggestionsTitle {
  font-size: 11px;
  font-weight: 500;
  color: #dce0ec;
}

.mentionSuggestionsTextBox {
  margin-left: 15px; 
}