.verify2fa-container {
    .uik-content-title__wrapper {
        color: #3E3F42;
    }

    .uik-input__input {
        &:focus {
          border-color: #1b8c96;
        }
        &.error {
            border-color: #E67670;
        }
    }
    .button-two-state {
        margin-top: 16px;
        width: 100%;
    }
    .code-invalid {
        color: #E67670;
        font-weight: 500;
        font-size: 12px;
        margin-top: 5px;
    }
    .resend-verification {
        display: block;
        margin: 8px auto 0;
        background-color: transparent;
        border: none;
        color:  #1B8C96;;
        font-weight: 500;
        cursor: pointer;
        line-height: 22px;
    }
}