.button-two-state {
    background-color: rgb(27, 140, 150);
    position: relative;
    border: none;
    border-radius: 4px;
    padding: 10px;
    height: 40px;
    color: white;
    font-size: 0.875rem;
    font-weight: 450;
    overflow: hidden;
    transition: background-color 0.8s;
    .field {
        min-width: 110px;
        transition: all 0.25s;
        justify-content: center;
        display: flex;
    }
    &:hover {
        cursor: pointer;
    }
    .disabled {
        margin-top: 10px;
        opacity: 0;
        &-shown {
            transform: translateY(-28px);
            opacity: 1;
        }
        div:first-child{
            align-items: center;
            img {
                margin-right: 8px;
                width: 18px;
                height: 18px;
            }
        }
    }
    .active {
        transform: translateY(-29px);
        opacity: 0;
        &-shown {
            transform: translateY(0px);
            opacity: 1;
        }
    }
    &.clicked {
        color: #9ea0a5;
        background-color: rgb(234, 237, 243);
    }
    &:focus{
        outline: none;
    }
    &:disabled{
        cursor: not-allowed;
    }
}