.two-fa-new-account {
    .hs-chat-container {
        .hs-chat {
            background-color: white;
        }
        .hs-chat__closed {
            background-color: transparent;
        }
    }
    .container {
        margin-top: 100px;
        flex-direction: column;
        display: flex;
        align-items: center;
        width: 100%;

        .steps {
            color: #45546E;
            display: flex;
            margin-top: 40px;
            .step-container {
                margin-left: 8px;
                margin-right: 8px;
            }
            .divider {
                width: 73px;
                height: 2px;
                background-color: #45546E;
                border-radius: 5px;
                margin-top: 19.5px;
                &-complete {
                    background-color: #1B8C96;;
                }
            }
        }

        .content {
            width: 384px;
            background-color: white;
            margin-top: 50px;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
            border: 2px solid #EAEDF3;
            border-radius: 8px;
            padding: 32px;

            .title {
                font-weight: 600;
                font-size: 16px;
            }
        }

        .description {
            font-weight: 500;
            font-size: 12px;
            color: #9EA0A5;
            margin-top: 8px;
            line-height: 22px;
            .update-phone-number {
                color: #1B8C96;
                cursor: pointer;
            }
        }

        .setpassword-container {
            margin-top: 32px;
        }

        .verify2fa-container {
            margin-top: 34px;
        }

        .verification-success {
            .button-primary__original {
                width: 100%;
                margin-top: 16px;
            }
        }
    }
}