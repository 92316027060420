.referenceViewer {
  height: 100vh;
  width: 100vw;
  background-color: #393d50;
  display: flex;
}

.referenceDocumentContainer {
  flex-basis: 75%;
  .titleDescriptionContainer {
    display: flex;
    justify-content: space-between;
    .documentDescription {
      img {
        cursor: pointer;
      }
      .descriptionBox {
        position: absolute;
        img {
          transform: rotate(90deg);
          left: 4px;
          top: -9px;
          position: absolute;
        }
        .descriptionText {
          width: 320px;
          min-height: 40px;
          background: black;
          border-radius: 4px;
          position: absolute;
          top: 5px;
          transform: translateX(-50%);
          z-index: 1;
          color: #eaedf3;
          word-wrap: break-word;
          padding: 18px;
          box-sizing: border-box;
          font-size: 11px;
        }
      }
    }
  }
}

.sideBarContainer {
  flex-basis: 25%;
  overflow-y: scroll;
  .referencesContainer {
    height: calc(100% - 265px) !important;
    overflow-y: auto;
    scrollbar-color: rgb(157, 168, 181) rgb(50, 53, 68);
    scrollbar-width: thin;
    padding-bottom: 20px;
    .pageNumber {
      margin-left: 42px;
      margin-bottom: 8px;
      color: #dccaa9;
      font-size: 12px;
      font-weight: 600;
      margin-top: 19px;
    }
    .dividerContainer {
      margin: 0 30px;
      border-top: 1px solid #5b5f72;
    }
    .referenceCaption {
      margin: 20px 42px 10px;
      color: white;
      font-weight: 500;
      font-size: 12px;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;  
      overflow: hidden;
    }
    .referenceLinks {
      margin-left: 42px;
      display: flex;
      color: #bac0cb;
      font-weight: 600;
      font-size: 12px;
      div {
        margin-left: 4px;
      }
      margin-bottom: 16px;
    }
  }
}

.headerContainer {
  padding-top: 20px;
  padding-left: 40px;
  padding-bottom: 20px;
}

.referenceContainer {
  height: calc(100% - 97px);
}

.documentReferenceContainer {
  cursor: pointer;
}

.sidebarHeader {
  margin: 20px 42px 20px;
  .closeButton {
    display: flex;
    justify-content: flex-end;
    margin-left: 25px;
    img {
      cursor: pointer;
    }
  }
  .searchInputField {
    margin: 0 auto;
    margin-top: 22px;
    background-color: #2c3041;
    border: none;
    color: #8a8e96;
    &::placeholder {
      color: #8a8e96;
      background-image: url("../../images/icons/svg/search.svg");
      background-repeat: no-repeat;
      background-position: right;
    }
  }
}

// Mozilla firefox specific styling for the input placeholder

@-moz-document url-prefix() {
  .searchInputField {
    &::placeholder {
      background-position-y: 50% !important;
      background-position-x: 96.5% !important;
    }
  }
}

.modalTitleLabel {
  text-transform: uppercase;
  color: #9ea0a5;
  font-size: 12px;
  font-weight: 600;
}

.modalDescriptionLabel {
  text-transform: uppercase;
  color: #9ea0a5;
  font-size: 12px;
  font-weight: 600;
  margin-top: 15px;
}

.titleModalInput {
  font-size: 14px;
  padding: 8px 16px;
  border: solid 1px #d6dce8;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  outline: none;

}

.descriptionModalInput {
  font-size: 14px;
  padding: 8px 16px;
  border: solid 1px #d6dce8;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  outline: none;
  resize: none;
  height: 95px;

}

.deleteReferenceModalBody {
  font-size: 14px;
  font-weight: 500;
  color: #6b6c6f;
}

.header {
  font-size: 24px;
  font-weight: 500;
  color: white;
  margin-bottom: 6px;
}

.documentTitle {
  font-size: 18px;
  font-weight: 500;
  color: white;
}

.sidePanelContainer {
  padding-left: 0 !important;
}

.sidePanel {
  border-left: 1px solid #eaedf3;
  min-height: 100vh;
  height: 100%;
}

.sidePanelHeader {
  border-bottom: 1px solid #eaedf3;
  color: #3e3f42;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin: 0;
  padding: 20px;
  padding-top: 26px;
}

.sidePanelOptions {
  border-bottom: 1px solid #eaedf3;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  padding: 13px 30px;
}

.referenceActionsDropDown {
  float: right;
}

.referenceActionsDropDownContainer {
  color: #9ea0a5;
  cursor: pointer;
  font-size: 10px;
}

.backLink {
  color: #b6b6b6 !important;
  margin-right: 10px;
}

.renameContentContainer {
}

.renameContentHeadline {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  padding: 5px 25px;
}

.renameContentFormContainer {
  padding: 24px;
}

.renameContentFormButtons {
  padding: 20px 30px;
}

.renameCancelButton {
  margin-right: 20px;
}

.approvalsLink {
  color: #3e3f42;
  font-size: 12px;
  font-weight: 500;
}

.approvalsRemaining {
  color: hsla(184.8780487804878, 69.49%, 34.71%, 1);
  display: block;
  float: right;
}

.approvals {
  display: block;
  float: left;
}

.uploadNewVersionLink {
  color: #3e3f42 !important;
}

.conversationIconContainer {
  background-color: #ff9c79;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
  height: 28px;
  width: 28px;
}

.conversationIcon {
  font-size: calc(28px / 2); /* 50% of parent */
  line-height: 1;
  position: relative;
  top: calc(28px / 7); /* 25% of parent */
}

.closeComment {
  color: #9ea0a5;
  font-size: 20px;
  float: right;
  height: 16px;
  width: 16px;
}

.notCommentPanel {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.cancelButton {
  color: #fff;
}

.confirmButton {
  color: #7a6a8d;
  margin-left: 10px;
}

.highlight {
 background-color: #DAA520;
}

.container {
  display: flex;
  justify-content: flex-end;
}

.doneReferenceButton {
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  position: -webkit-sticky; /* Safari */
  bottom: 0;
  background-color: #393d50;
  height: 100px;
  box-shadow: 0px 0px 0px #000, 0px -5px 5px rgba(50,50,50,0.45);
  :global(.button-primary) {
    width: 90%;
  }
}

.referencesContainer::-webkit-scrollbar-track {
  background-color: rgb(50, 53, 68);
}

.referencesContainer::-webkit-scrollbar {
  width: 8px;
}

.referencesContainer::-webkit-scrollbar-thumb {
  background-color: rgb(146, 157, 170);
  border-radius: 8px;
}
