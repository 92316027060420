.references-library {
    width: 100%;
    position: absolute;
    top: 0;
    background-color: #393d50;
    z-index: 1;
    .container {
        padding: 15px;
        .header {
            display: flex;
            justify-content: space-between;
            padding: 8px 20px 0;
            margin-bottom: 20px;
            .left-content {
                .title {
                    font-size: 24px;
                    font-weight: 500;
                    color: #ffffff;
                }
                .sub-title {
                    margin-top: 4px;
                    color: #d6dce8;
                    font-size: 18px;
                    font-weight: 500;
                }
            }
            .right-content {
                .first-row {
                    display: flex;
                    align-items: center;
                    .upload-button {
                        display: flex;
                        .button-primary {
                            width: 89px;
                            margin-right: 25px;
                        }
                        margin-right: 25px;
                    }
                    .upload-button-hide {
                        display: none;
                    }
                    .close-button {
                        cursor: pointer;
                        margin-right: 5px;
                    }
                }
                .second-row {
                    display: none;
                    margin-top: 20px;
                    justify-content: flex-end;
                    .search-button {
                        cursor: pointer;
                        margin-right: 30px;
                    }
                    .sort-button {
                        cursor: pointer;
                    }
                }
            }
        }

        .body {
            display: flex;
            flex-wrap: wrap;
            .document-container {
                width: 250px;
                border: 1px solid #eaedf3;
                box-sizing: border-box;
                border-radius: 4px;
                .title {
                    background-color: white;
                    padding: 15px;
                }
                .thumbnail-image {
                    background-size: cover !important;
                    height: 210px;
                }
                margin-bottom: 20px;
            }
            .emptyLibraryMessageContainer {
                width: 100%;
                display: flex;
                justify-content: center;
                margin-top: 100px;
                .messageComponentsContainer {
                    background-color: #2C3040;
                    padding: 18px 20px 18px 30px;
                    color: white;
                    margin-bottom: 33px;
                    border-radius: 4.1px;
                    font-size: 14px;
                    font-weight: 500;
                    display: flex;
                    .messageText {
                    display: flex;
                    align-items:center;
                    margin-right: 80px;
                    width: 300px;
                    }
                    .uploadButton {
                    margin-left: auto;
                    order: 2;
                    }
                }
            }
        }
    }
}

.reference-file-uploader {
    border: 1px dashed #e2e5ed;
    border-radius: 4px;
    padding: 16px 16px 16px 25px;
    .uploader-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .uploader-container-left {
            display: flex;
            align-items: center;
            .icon {
                margin-right: 15px;
            }
            .text {
                color: #9ea0a5;
            }
        }
        .uploader-container-right {
            display: flex;
            align-items: center;
        }
    }
}

.reference-upload-success {
    border: 1px solid #eaedf3;
    border-radius: 4px;
    padding: 16px 16px 16px 25px;
    background-color: #fbfbfd;

    .uploader-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .uploader-container-left {
            display: flex;
            align-items: center;
            .icon {
                margin-right: 15px;
                color: #9ea0a5;
            }
            .text {
                color: #3e3f42;
            }
        }
        .uploader-container-right {
            display: flex;
            align-items: center;
        }
    }
}

.reference-document-uploaded-details {
    .label {
        text-transform: uppercase;
        color: #9ea0a5;
        font-size: 12px;
        font-weight: 600;
        margin-top: 34px;
    }
    .title-input {
        margin-top: 6px;
        input {
            font-size: 14px;
            padding: 8px 16px;
            border: solid 1px #d6dce8;
            border-radius: 4px;
            width: 100%;
            box-sizing: border-box;
            outline: none;
        }
    }
    .description-input {
        margin-top: 6px;
        textarea {
            font-size: 14px;
            padding: 8px 16px;
            border: solid 1px #d6dce8;
            border-radius: 4px;
            box-sizing: border-box;
            width: 100%;
            outline: none;
            resize: none;
            height: 95px;
        }
    }
    .share-reference-container {
        margin-top: 30px;
        .uik-checkbox__label {
            color: #3e3f42;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
        }
        .subtitle {
            color: #8a8e96;
            margin-top: 10px;
            font-size: 12px;
            font-weight: 500;
        }
    }
}