.draftUploadForm {
  background: #fff;
  border: 1px solid #eaedf3;
  border-radius: 4px;
  margin: 30px auto;
  padding: 30px !important;
  width: 730px;
}

.uploadError {
  width: 470px;
  height: 22px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: #e67670;
  float: left;
  width: 86%;
}

.draftUploadFormActions {
  background: none;
  margin: 30px auto;
  width: 730px;
}

.buttonRow {
  justify-content: space-between !important;
  flex-direction: row-reverse;
}

.mainContentUploader {
  border: 1px dashed #efefef;
  border-radius: 4px;
  padding: 10px 20px;
  vertical-align: middle;
}

.mainContentUploading {
  background-color: #fbfbfd;
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 10px 20px;
  vertical-align: middle;
}

.innerContentUploading {
  line-height: 30px;
  float: left;
  width: 100%;
}

.uploaderIcon {
  color: #9ea0a5;
  display: inline-block;
  line-height: 30px;
  margin: 10px 10px 0;
  float: left;
}

.uploaderIconCheck {
  color: #15cd93;
  display: inline-block;
  float: right;
  line-height: 22px;
  margin: 10px 0 0 0;
  float: left;
}

.uploaderPlaceholder {
  color: #9ea0a5;
  font-size: 14px;
  line-height: 45px;
}

.uploaderButton {
  float: right;
}

.uploadProgress {
  height: 4px;
  width: 100%;
  border-radius: 2px;
}

.uploadingFilename {
  color: #3e3f42;
  font-size: 14px;
  line-height: 45px;
  float: left;
  padding-top: 2px;
  width: 86%;
}

.referenceContentUploader {
  border: 1px dashed #efefef;
  border-radius: 4px;
  padding: 10px 20px;
  vertical-align: middle;
}

.referenceContentUploading {
  background-color: #fbfbfd;
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 10px 20px;
  vertical-align: middle;
}

.cancelButton {
  width: 122px;
  float: left;
  border: 1px solid #1b8c96 !important;
  color: #1b8c96;
}

.nextButton {
  width: 122px;
  float: right;
}

.checkMark {
  width: 32px;
  height: 32px;
  object-fit: contain;
}

.metadataSingleLineInputFields {
  float: left;
  width: 45%;
}

.metadataMultilineInputFields {
  float: left;
  margin-left: 30px;
  width: 50%;
}

.processingContainer {
  float: right;
  line-height: 32px;
}

.notesInput {
  margin-top: 2rem;
}
.notesTextarea{
  resize: none;
  margin-top: 14px;
  &:focus{
    border-color: #D6DCE8;
  }
}
