.setup2FA-container {
    margin-top: 32px;

    .label {
        font-weight: 600;
        font-size: 12px;
        color: #3E3F42;
    }

    .react-tel-input {
        margin-top: 12px;
        .form-control {
            width: 100%;
            height: 38px;
            font-size: 14px;
            font-weight: 500;
            &:focus {
                box-shadow: none;
                border-color: #1b8c96;
            }
        }
    }

    .button-primary__original {
        width: 100%;
        margin-top: 16px;
    }

    .setup-later {
        margin-top: 10px;
        button {
            border: none;
            background-color: transparent;
            color: #1B8C96;
            margin: 0 auto;
            display: block;
            font-weight: 500;
            cursor: pointer;
        }
    }
    &.error {
        .react-tel-input {
            .form-control {
                border-color: #E67670;
            }
        }
    }

    .invalid-number {
        color: #E67670;
        font-weight: 500;
        font-size: 12px;
        margin-top: 5px;
    }
}