.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(57, 61, 80, 0.8);
  overflow-y: auto;
  z-index: 7000;
}

.modal-body {
  background-color: white;
  border-radius: 4px;
  margin: 100px auto;
  &-alert {
    max-width: 540px;
  }
  &-regular{
    max-width: 730px;
  }
  .heading {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    padding: 24px 30px;
  }
  .body {
    padding: 30px;
    line-height: 1.4em;
  }
  .buttons {
    display: flex;
    flex-direction: row-reverse;
    padding: 18px 28px;
    button {
      margin: 10px;
      width: 132px;
    }
  }
}
