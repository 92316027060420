.conversationsPanel {
    background-color: #393d50;
    color: #fff;
    z-index: 1;
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    width: 25%;
    overflow-y: scroll;

    .header {
        margin: 0 auto;
        padding: 16px 3% 26px;
        width: 88%;

        .titleContainer {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .leftContainer {
                .title {
                    font-size: 20px;
                    font-weight: 500;
                }
            }

            .rightContainer {
                display: flex;
                align-items: center;

                .sortContainer {
                    margin-right: 10px;
                    position: relative;

                    .sortButton {
                        cursor: pointer;
                        display: flex;
                        height: 20px;
                        width: 20px;
                    }
    
                    .sortOptions {
                        position: absolute;
                        width: 150px;
                        background-color: white;
                        right: 0;
                        box-shadow: 0 6px 22px 0 rgba(0, 0, 0, 0.3);
                        border-radius: 4px;
                        z-index: 1;

                        .option {
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            text-transform: capitalize;
                            height: 44px;
                            box-sizing: border-box;
                            padding: 11px 20px;
                            color: #393d50;;
                            font-size: 14px;
                            font-weight: 500;
                            
                            &.optionSelected {
                                color: #1b8c96;
                                justify-content: space-between;

                                .checkmark {
                                    display: flex;
                                }
                            }
                        }

                        &> .option:not(:last-child) {
                            border-bottom: 1px solid #dddddd;
                        }
                    }
                }

                .closeButton {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    .closeIcon {
                        height: 16px;
                        width: 16px;
                    }
                }
            }
        }

        .searchInputContainer {
            display: none !important;
            .searchInputField {
                margin: 0 auto;
                margin-top: 22px;
                background-color: #2c3041;
                border: none;
                color: #8a8e96;
                &::placeholder {
                  color: #8a8e96;
                  background-image: url("../../images/icons/svg/search.svg");
                  background-repeat: no-repeat;
                  background-position: top right;
                }
              }
        }
    }
    .body {
        .page {
            .dividerContainer {
                margin: 15px 4%;
                border-top: 1px solid #5b5f72;
            }
            .title {
                width: 88%;
                text-transform: uppercase;
                margin: 0 auto;
                color: #dccaa9;
                font-size: 12px;
                font-weight: 600;
                margin-bottom: 5px;
            }
            .emptyMessage {
                height: 100%;
                width: 88%;
                background-color: #2C3040;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 20px 0px 20px 0px;
                border-radius: 4px;
            }
            .messageContainer {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .conversation {
                width: 88%;
                margin: 0 auto;
                cursor: pointer;

                .dotSeparator {
                    font-size: 4px;
                }
                
                .metadata {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .leftContainer {
                        display: flex;
                        
                        .commentSVG {
                            width: 16px;
                        }
                        .nameDate{
                            display: flex;
                            align-items: center;
                            .name {
                                font-weight: 500;
                                margin-left: 4px;
                                margin-right: 4px;
                                font-size: 13px;
                            }
                            .date {
                                margin-left: 4px;
                                font-size: 12px;
                                color: #d6dce8;
                                position: relative;
    
                                .fullDateTimeContainer {
                                    display: none;
    
                                    .fullDateTime {
                                        position: absolute;
                                        background-color: black;
                                        top: -25px;
                                        left: 50%;
                                        transform: translateX(-50%);
                                        white-space: nowrap;
                                        border-radius: 2px;
                                        padding: 3px 10px;
                                        color: white
                                    }
    
                                    .bottomArrow {
                                        position: absolute;
                                        width: 0;
                                        height: 0;
                                        left: 50%;
                                        top: -4px;
                                        border-top: 3px solid black;
                                        border-left: 3px solid transparent;
                                        border-right: 3px solid transparent;
                                    }
                                }
                            }
                            .date:hover .fullDateTimeContainer {
                                display: block;
                            }
                        }
                        .numberComments {
                            font-size: 12px;
                            margin-left: 4px;
                            margin-right: 4px;
                            color: #d6dce8;
                            display: flex;
                            align-items: center;
                        }
                    }
                    .rightContainer {
                        display: flex;

                        .noCommentContainer {
                            display: flex;
                            font-size: 10px;
                            align-items: center;
                            .arrowRight {
                                display: flex;
                                margin-left: 10px;
                            }
                        }
                        .checkmarkContainer {
                            position: relative;
                            .toggleDisabledContainer {
                                display: none;
                                font-size: 12px;
    
                                .disabledText {
                                    position: absolute;
                                    background-color: black;
                                    top: -28px;
                                    right: 0;
                                    white-space: nowrap;
                                    border-radius: 2px;
                                    padding: 3px 10px;
                                    color: white;

                                    .alignDot {
                                        line-height: 4px;
                                        vertical-align: middle;
                                    }
                                }
    
                                .bottomArrow {
                                    position: absolute;
                                    width: 0;
                                    height: 0;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    top: -7px;
                                    border-top: 3px solid black;
                                    border-left: 3px solid transparent;
                                    border-right: 3px solid transparent;
                                }
                            }
                        }

                        .checkmarkContainer:hover .toggleDisabledContainer{
                            display: block;
                        }
                    }
                }

                .latestCommentText {
                    font-size: 12px;
                    font-weight: 500;
                    margin-top: 10px;
                }
            }
        }
    }
}