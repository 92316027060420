.hs-chat {
  height: 38px;
  width: 38px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.hs-chat-dark-mode {
  background-color: rgba(0, 0, 0, 0) !important;
}
