.commentPanel {
  background-color: #393d50;
  color: #fff;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  width: 25%;
  overflow-y: scroll;
}

.comment {
  padding: 0 20px;
  word-break: break-word;
  white-space: pre-wrap;
  cursor: pointer;
}

.messageInput {
  border-top: 1px solid rgba(234, 237, 243, 0.2);
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0;
  opacity: 1;
  background: #393d50;
  width: 100%;

  z-index: 99;
}

.messageInputField,
.messageInputField:focus {
  background-color: #393d50;
  border: none;
  color: #fff;
  font-size: 14px;
  border-width: 0px;
  outline: none !important;
  padding: 20px 20px;
  resize: none;
  width: 80%;
}

.messageInputSubmit {
  display: flex;
  padding: 0px 15px 20px 15px;
  justify-content: flex-end;
  .sendButton {
    cursor: pointer;
    background: none;
    border: none;
    width: 16px;
    height: 16px;
    overflow: hidden;
    padding: 0;

    .hideSendButton {
      display: none;
    }

    .loading {
      width: 16px;
      height: 16px;
      animation: spin 1s infinite;
    }

    &:focus{
      outline: none;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.sidePanelOptions {
  border-bottom: 1px solid rgba(234, 237, 243, 0.2);
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  padding: 20px 0px 0px;

  overflow-y: auto;
  height: calc(100% - 245px);
}

.sidePanelHeader {
  border-bottom: 1px solid rgba(234, 237, 243, 0.2);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin: 0;
  padding: 20px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  opacity: 1;
  background: #393d50;
  z-index: 99;

  .checkmark {
    float: right;
    margin: 2px 25px 0 0;
  }
}

.conversationIconContainer {
  background-color: #ff9c79;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
  height: 28px;
  width: 28px;
}

.conversationIcon {
  font-size: calc(28px / 2); /* 50% of parent */
  line-height: 1;
  position: relative;
  top: calc(28px / 7); /* 25% of parent */
}

.closeComment {
  color: #9ea0a5;
  cursor: pointer;
  font-size: 26px;
  float: right;
  height: 16px;
  width: 16px;
}

.conversationActionsDropDownContainer {
  float: right;
  margin: 1px 25px 0 0;
  cursor: pointer;
}

.commentSendIcon {
  border: none;
}

.moreIcon {
  position: relative;
  top: 3px;
}

.addTimestamp {
  font-size: 0.75rem;
  margin: 5px 20px;
}

.editor {
  width: calc(100% - 40px);
  margin-left: 20px;
  padding: 20px 0;
  font-size: 12px;
}

.editor :global(.public-DraftEditor-content) {
  overflow: scroll;
  min-height: 140px;
  max-height: 50vh;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.sidePanelHeadingTitle {
  font-size: 24px;
  font-weight: 500;
}

.backArrow {
  margin-right: 10px;
  cursor: pointer;
}